import React from 'react';

import { Section } from '@/components/green-edge-landing/common/section';
import { Container } from '@/components/green-edge-landing/common/container';

import { Accordion } from '@/components/green-edge-landing/common/accordion';

import { FAQ_ACCORDION_SCHEMA } from '@/constants';

import s from './style.module.scss';

export const SectionFaq = () => (
  <Section className={s.root}>
    <Container className={s.container}>
      <div className={s.title}>Top 5 questions people ask us:</div>
      <Accordion schema={FAQ_ACCORDION_SCHEMA} />
    </Container>
  </Section>
);
