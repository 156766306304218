import cn from 'classnames';
import { type FC } from 'react';
import { type TComponentProps } from '@/components/myinsurance-landing/common/types';
import s from './style.module.scss';

type TContainerProps = TComponentProps & {
  size?: 'sm' | 'md' | 'lg';
};

export const Container: FC<TContainerProps> = ({
  className,
  children,
  size = 'md',
}) => (
  <div className={cn(className, s.container, s[`size_${size}`])}>
    {children}
  </div>
);
