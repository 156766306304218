import React from 'react';
import s from './style.module.scss';
import { ReactComponent as ArrowDownIcon } from './images/arrow-down.svg';

type TAccordionItemProps = {
  id: string;
  title: string;
  content: React.ReactNode;
};

export const AccordionItem: React.FC<TAccordionItemProps> = ({
  id,
  title,
  content,
}) => {
  const itemId = `accordion-item-${id}`;

  return (
    <label htmlFor={itemId} className={s.accordionItem}>
      <input id={itemId} type="checkbox" name="accordion" />

      <div className={s.accordionItem__title}>
        {title}

        <ArrowDownIcon className={s.accordionItem__arrow} />
      </div>

      <div className={s.accordionItem__content}>{content}</div>
    </label>
  );
};

type TAccordionProps = {
  schema: Array<{ title: string; content: string }>;
};

export const Accordion: React.FC<TAccordionProps> = ({ schema }) => (
  <div className={s.accordion}>
    {schema.map((entry, index) => (
      <AccordionItem
        key={entry.title}
        id={index.toString()}
        title={entry.title}
        content={entry.content}
      />
    ))}
  </div>
);
