import { useEffect, useRef } from 'react';
import { gtag } from '@insurance/utils';
import { AnalyticsGoals } from '@insurance/form-api';
import { type TOffer } from '@insurance/entities';
import { formApi } from '@/services/form-api';
import { useSessionOffers } from '@/hooks/use-session-offers';
import { OfferWall } from '../offer-wall';

interface TSessionOffersProps {
  visibleCount?: number;
  currentEstDiscount: number;
  discountUpdating?: boolean;
}

export const SessionOffers: React.FC<TSessionOffersProps> = ({
  visibleCount,
  currentEstDiscount,
  discountUpdating,
}) => {
  const { offers, loading, loaded, failed } = useSessionOffers();

  const visibleOffers =
    visibleCount !== undefined ? offers.slice(0, visibleCount) : offers;

  const handleOfferClick = (offer: TOffer) => {
    gtag({ event: 'click_to_listing' });

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.ClickOfferInOfferWall,
      payload: {
        key: offer.key,
        offerNumber: offers.findIndex(
          (currentOffer) => currentOffer.key === offer.key,
        ),
        source: 'landing-offer-wall',
      },
    });
  };

  const isShowOfferWallTrackedRef = useRef(false);

  useEffect(() => {
    if (isShowOfferWallTrackedRef.current || loading || !loaded) {
      return;
    }

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.ShowOfferWall,
      payload: {
        count: visibleOffers.length,
        offers: visibleOffers.map((offer) => offer.key || ''),
        source: 'landing-offer-wall',
      },
    });

    isShowOfferWallTrackedRef.current = true;
  }, [loading, loaded, visibleOffers]);

  if (failed) {
    return <div>Offers loading failed</div>;
  }

  return (
    <OfferWall
      offers={visibleOffers}
      loading={loading}
      skeletonsCount={visibleCount}
      onOfferClick={handleOfferClick}
      currentEstDiscount={currentEstDiscount}
      discountUpdating={discountUpdating}
    />
  );
};
