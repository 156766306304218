import cn from 'classnames';
import { inter } from '@/styles/fonts';
import React, { useState } from 'react';
import { type TModalContentProps } from '@/components/green-edge-landing/common/inactivity-modal/content/base/types';
import { NoIcon } from '@/components/green-edge-landing/common/inactivity-modal/content/car-insurance/icons/no-icon';
import { YesIcon } from '@/components/green-edge-landing/common/inactivity-modal/content/car-insurance/icons/yes-icon';
import { CloseIcon } from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';
import s from './style.module.scss';

type TCarInsuranceModalContentProps = TModalContentProps & {
  onSelect(value: boolean): void;
};

type TCarInsuranceSelectedOption = 'yes' | 'no';

export const CarInsuranceModalContent: React.FC<
  TCarInsuranceModalContentProps
> = ({ className, isShown, onClose, title, onSelect }) => {
  const [selectedOption, setSelectedOption] =
    useState<TCarInsuranceSelectedOption | null>(null);

  const handleOptionClick = (value: TCarInsuranceSelectedOption) => {
    setSelectedOption(value);
    onSelect(value === 'yes');
  };

  const handleYesClick = () => handleOptionClick('yes');

  const handleNoClick = () => handleOptionClick('no');

  return (
    <div
      className={cn(
        className,
        s.root,
        { [s.isShown]: isShown },
        inter.className,
      )}
    >
      <div className={s.main}>
        <div className={s.closePanel}>
          <button className={s.button_close} type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>

        {title && (
          <div className={s.header}>
            <h2 className={s.title}>{title}</h2>
          </div>
        )}

        <div className={s.options}>
          <button
            className={cn(s.option, { [s.active]: selectedOption === 'yes' })}
            type="button"
            onClick={handleYesClick}
          >
            <YesIcon />
            <span className={s.label}>Yes</span>
          </button>

          <button
            className={cn(s.option, { [s.active]: selectedOption === 'no' })}
            type="button"
            onClick={handleNoClick}
          >
            <NoIcon />
            <span className={s.label}>No</span>
          </button>
        </div>
      </div>
    </div>
  );
};
