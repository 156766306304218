import React, { useEffect, useMemo } from 'react';
import { AnalyticsGoals } from '@insurance/form-api';
import { getUrlWithParams, gtag, type InsuranceHost } from '@insurance/utils';
import { inter } from '@/styles/fonts';
import { formApi } from '@/services/form-api';
import { useLandingContent } from '@/hooks/content';
import { useToggle } from '@insurance/hooks';
import { useInactivityModalState } from '@/hooks/use-inactivity-modal-state';
import { CarInsuranceModalContent } from './common/inactivity-modal/content/car-insurance';
import { SectionHero } from './sections/hero';
import { SectionWhyUs } from './sections/why-us';
import { SectionFaq } from './sections/faq';
import { SectionFooter } from './sections/footer';
import { ContactFormModal } from './common/contact-form-modal';
import { InactivityModal } from './common/inactivity-modal';

type TGreenEdgeLandingProps = {
  formBaseUrl?: string;
  withFormNextStep?: boolean;
  usStateParam: string;
  host: InsuranceHost;
};

export const GreenEdgeLanding: React.FC<TGreenEdgeLandingProps> = ({
  formBaseUrl,
  usStateParam,
  withFormNextStep,
  host,
}) => {
  const { title, subtitle, buttonText } = useLandingContent({
    defaultTitle: (
      <>
        Compare car <br />
        insurance rates
      </>
    ),
    defaultSubtitle: (
      <>
        Get a quote and see how
        <br /> much you can save
      </>
    ),
    defaultButtonText: 'View My Quotes',
  });

  const { opened: inactivityModalOpened, close: closeInactivityModal } =
    useInactivityModalState();

  useEffect(() => {
    if (inactivityModalOpened) {
      formApi.trackAnalyticsEvent({
        goal: AnalyticsGoals.InactivityDialogOpened,
      });
    }
  }, [inactivityModalOpened]);

  const handleStart = (
    values: { zipCode?: string; isCurrentInsurance?: number } = {},
  ) => {
    gtag({ event: 'form_start' });

    closeInactivityModal();

    const baseUrlWithParams = formBaseUrl
      ? getUrlWithParams(formBaseUrl, values as Record<string, unknown>)
      : undefined;

    formApi.openForm({
      baseUrl: baseUrlWithParams,
      values,
      withNextStep:
        withFormNextStep || typeof values.isCurrentInsurance !== 'undefined',
    });
  };

  const handleInactivityModalPrimaryAction = (value: boolean) => {
    closeInactivityModal();

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingGoToForm,
      payload: {
        referrer: 'inactivityDialog',
      },
    });

    handleStart({ isCurrentInsurance: Number(value) });
  };

  const inactivityModalTitle = useMemo(
    () => (
      <>
        <span>Do you have a car</span>
        <br />
        <span>insurance?</span>
      </>
    ),
    [],
  );

  const {
    enabled: contactFormModalOpened,
    enable: openContactFormModal,
    disable: closeContactFormModal,
  } = useToggle();
  return (
    <main className={inter.className}>
      <SectionHero
        title={title}
        subtitle={subtitle}
        buttonText={buttonText}
        onGetQuotes={handleStart}
        usStateParam={usStateParam}
      />

      <SectionWhyUs host={host} />

      <SectionFaq />

      <SectionFooter onContactClick={openContactFormModal} />

      <ContactFormModal
        isOpen={contactFormModalOpened}
        onClose={closeContactFormModal}
      />

      <InactivityModal
        isShown={inactivityModalOpened}
        onClose={closeInactivityModal}
        template={
          <CarInsuranceModalContent
            isShown={inactivityModalOpened}
            onClose={closeInactivityModal}
            title={inactivityModalTitle}
            onSelect={handleInactivityModalPrimaryAction}
          />
        }
      />
    </main>
  );
};
