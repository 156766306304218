import { type TOffer } from '@insurance/entities';
import { Rating } from '../rating';
import { Button } from '../button';
import s from './style.module.scss';

const MAX_DISCOUNT_VALUE = 26;

interface TOfferWallItemProps {
  offer: TOffer;
  rating: number;
  votes: string;
  onClick(offer: TOffer): void;
  currentEstDiscount: number;
}

export const OfferWallItem: React.FC<TOfferWallItemProps> = ({
  offer,
  rating,
  votes,
  onClick,
  currentEstDiscount,
}) => {
  const handleClick = () => onClick(offer);

  const discountValue =
    currentEstDiscount === 0 ? '-' : `~${currentEstDiscount}%`;

  return (
    <div className={s.root}>
      <img className={s.logo} src={offer.logo} alt={offer.key} />

      <Rating className={s.rating} value={rating} votes={votes} />

      <div className={s.discount}>
        <span className={s.discount__label}>Est. Discount</span>
        <span className={s.discount__value}>{discountValue}</span>
      </div>

      <div className={s.discount}>
        <span className={s.discount__label}>Max.Est. Discount</span>
        <span className={s.discount__value}>~{MAX_DISCOUNT_VALUE}%</span>
      </div>

      <Button
        className={s.button}
        variant="filled"
        color="secondary"
        size="md"
        href={offer.link}
        target="_blank"
        onClick={handleClick}
      >
        Get Quote
      </Button>
    </div>
  );
};
