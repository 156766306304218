import cn from 'classnames';
import React from 'react';
import s from './style.module.scss';
import { ReactComponent as CheckedIcon } from './images/check.svg';

type TFilterControlProps = {
  name: string;
  discount: number;
  checked: boolean;
  onCheck(): void;
};

export const FilterControl: React.FC<TFilterControlProps> = ({
  name,
  discount,
  checked,
  onCheck,
}) => (
  <button
    type="button"
    className={cn(s.root, { [s.checked]: checked })}
    onClick={onCheck}
  >
    <span className={s.textBlock}>
      <span className={s.name}>{name}</span>
      <span className={s.discount}>{discount}%</span>
    </span>

    {checked && <CheckedIcon />}
  </button>
);
