import { times } from '@insurance/utils';
import { type TOffer } from '@insurance/entities';
import { OfferWallItem } from '../offer-wall-item';
import { OfferWallSkeleton } from '../offer-wall-skeleton';
import s from './style.module.scss';

const ratings = [
  {
    value: 4.8,
    votes: '4,115',
  },
  {
    value: 4.6,
    votes: '2,869',
  },
  {
    value: 4.5,
    votes: '2,567',
  },
];

const OfferWallHeader: React.FC = () => (
  <div className={s.header}>
    <span>Insurer</span>
    <span>Rating</span>
    <span>Est. Discount</span>
    <span>Max.Est. Discount</span>
    <span />
  </div>
);

export interface TOfferWallProps {
  offers: TOffer[];
  loading: boolean;
  skeletonsCount?: number;
  onOfferClick(offer: TOffer): void;
  currentEstDiscount: number;
  discountUpdating?: boolean;
}

export const OfferWall: React.FC<TOfferWallProps> = ({
  offers,
  loading,
  skeletonsCount = 3,
  onOfferClick,
  currentEstDiscount,
  discountUpdating,
}) => (
  <div className={s.root}>
    <OfferWallHeader />

    {discountUpdating || loading
      ? times(skeletonsCount, (index) => (
          <OfferWallSkeleton key={`skeleton-${index}`} />
        ))
      : offers.map((offer, index) => (
          <OfferWallItem
            key={offer.key}
            offer={offer}
            rating={ratings[index % ratings.length].value}
            votes={ratings[index % ratings.length].votes}
            onClick={onOfferClick}
            currentEstDiscount={currentEstDiscount}
          />
        ))}
  </div>
);
