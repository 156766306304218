import { useEffect, type RefObject } from 'react';
import BezierEasing from 'bezier-easing';

const EASING = {
  linear: [0, 0, 1, 1],
  ease: [0.25, 1, 0.25, 1],
  easeIn: [0.42, 0, 1, 1],
  easeOut: [0, 0, 0.58, 1],
  easeInOut: [0.42, 0, 0.58, 1],
  easeInSine: [0.47, 0, 0.745, 0.715],
  easeOutSine: [0.39, 0.575, 0.565, 1],
  easeInOutSine: [0.445, 0.05, 0.55, 0.95],
  easeInQuad: [0.55, 0.085, 0.68, 0.53],
  easeOutQuad: [0.25, 0.46, 0.45, 0.94],
  easeInOutQuad: [0.455, 0.03, 0.515, 0.955],
  easeInCubic: [0.55, 0.055, 0.675, 0.19],
  easeOutCubic: [0.215, 0.61, 0.355, 1],
  easeInOutCubic: [0.645, 0.045, 0.355, 1],
  easeInQuart: [0.895, 0.03, 0.685, 0.22],
  easeOutQuart: [0.165, 0.84, 0.44, 1],
  easeInOutQuart: [0.77, 0, 0.175, 1],
  easeInQuint: [0.755, 0.05, 0.855, 0.06],
  easeOutQuint: [0.23, 1, 0.32, 1],
  easeInExpo: [0.95, 0.05, 0.795, 0.035],
  easeOutExpo: [0.19, 1, 0.22, 1],
  easeInOutExpo: [1, 0, 0, 1],
  easeInCirc: [0.6, 0.04, 0.98, 0.335],
  easeOutCirc: [0.075, 0.82, 0.165, 1],
  easeInOutCirc: [0.785, 0.135, 0.15, 0.86],
  easeInBack: [0.6, -0.28, 0.735, 0.045],
  easeOutBack: [0.175, 0.885, 0.32, 1.275],
  easeInOutBack: [0.68, -0.55, 0.265, 1.55],
};

type TGetProgress = (
  ref: TUseScrollProgress['trackedRef'],
  range: Array<number>,
  easing: TEasingName,
  origin: TUseScrollProgress['origin'],
) => number;

const getProgress: TGetProgress = (ref, range, easing, origin) => {
  const element = ref.current;

  const {
    height: eHeight = 0,
    bottom: eBottom = 0,
    top: eTop = 0,
  } = element?.getBoundingClientRect() || {};

  const rStart = range[0];
  const rEnd = range[1];
  const rDif = rEnd - rStart;

  let progress;

  switch (origin) {
    case 'top':
      if (eTop >= rEnd) {
        progress = 0;
      } else if (eTop <= rStart) {
        progress = 1;
      } else {
        progress = 1 - (eTop - rStart) / rDif;
      }
      break;
    case 'center':
      if (eTop + eHeight / 2 >= rEnd) {
        progress = 0;
      } else if (eTop + eHeight / 2 <= rStart) {
        progress = 1;
      } else {
        progress = 1 - (eTop + eHeight / 2) / rDif;
      }
      break;
    case 'bottom':
      if (eBottom >= rEnd) {
        progress = 0;
      } else if (eBottom <= rStart) {
        progress = 1;
      } else {
        progress = 1 - (eBottom - rStart) / rDif;
      }
      break;
    default:
      if (eTop >= rEnd) {
        progress = 0;
      } else if (eBottom <= rStart) {
        progress = 1;
      } else {
        progress = 1 - (eBottom - rStart) / (rDif + eHeight);
      }
  }

  const easingValues = EASING[easing];

  const easingFunction = BezierEasing(
    easingValues[0],
    easingValues[1],
    easingValues[2],
    easingValues[3],
  );

  return easingFunction(progress);
};

type TSetCssVarProgress = (
  trackedRef: TUseScrollProgress['trackedRef'],
  progress: number,
) => void;

const setCssVarProgress: TSetCssVarProgress = (trackedRef, progress) => {
  if (trackedRef.current) {
    trackedRef.current.style.setProperty('--scroll-progress', `${progress}`);
  }
};

type TOnScroll = (
  trackedRef: TUseScrollProgress['trackedRef'],
  outputRef: TUseScrollProgress['trackedRef'],
  range: Array<number>,
  origin: TUseScrollProgress['origin'],
  easing: TEasingName,
) => void;

const onScroll: TOnScroll = (
  trackedRef,
  outputRef,
  range,
  origin,
  easing = 'linear',
) => {
  requestAnimationFrame(() => {
    setCssVarProgress(
      outputRef,
      getProgress(trackedRef, range, easing, origin),
    );
  });
};

type TEasingName = keyof typeof EASING;

type TRange = Array<
  string | number | ((ref: RefObject<HTMLElement>) => number) | (() => number)
>;

type TUseScrollProgress = {
  trackedRef: RefObject<HTMLElement>;
  outputRef?: RefObject<HTMLElement>;
  range?: TRange;
  easing?: TEasingName;
  origin?: 'top' | 'center' | 'bottom';
};
export function useScrollProgress({
  trackedRef,
  outputRef = trackedRef,
  range: preRange = ['0%', '100%'],
  easing = 'easeInOut',
  origin,
}: TUseScrollProgress) {
  useEffect(() => {
    const range = preRange.map((v) => {
      switch (typeof v) {
        case 'number':
          return v;
        case 'function': {
          return v(trackedRef);
        }
        case 'string':
          return window.innerHeight * (parseFloat(v) / 100);
        default:
          return 0;
      }
    });

    const scrollListener = () =>
      onScroll(trackedRef, outputRef, range, origin, easing);

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [trackedRef, preRange, outputRef, origin, easing]);
}
