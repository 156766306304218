import React from 'react';
import cn from 'classnames';
import s from './style.module.scss';
import { ReactComponent as StarIcon } from './images/star.svg';

interface TProps {
  className?: string;
  value: number;
  votes?: string;
}

export const Rating: React.FC<TProps> = ({ className, value, votes }) => (
  <div className={cn(s.root, className)}>
    <StarIcon className={s.icon} />

    <div className={s.value}>{value}</div>

    {votes !== undefined && <div className={s.votes}>({votes})</div>}
  </div>
);
