import React, { type FC } from 'react';
import Modal from '@/components/common/modal';

type TInactivityModalProps = {
  isShown?: boolean;
  template: React.ReactNode;
  onClose?: () => void;
};

export const InactivityModal: FC<TInactivityModalProps> = ({
  onClose,
  isShown,
  template,
}) => <Modal isShown={isShown} onClose={onClose} template={template} />;
