import { InsuranceHost } from '@insurance/utils';
import desktopMobileImage from '../images/desktop-mobile.png';
import desktopMobileImageMyInsuranceMarket from '../images/desktop-mobile-mim.png';

export const getDesktopMobileImage = (host: InsuranceHost) => {
  switch (host) {
    case InsuranceHost.MyInsuranceMarket:
      return desktopMobileImageMyInsuranceMarket;
    default:
      return desktopMobileImage;
  }
};
