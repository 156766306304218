import React from 'react';

import s from './style.module.scss';

export const PeopleSavingCard: React.FC = () => (
  <div className={s.cardContainer}>
    <div className={s.personsBlock}>
      <div className={s.personsBlock__person}>
        <img src="/images/green-edge/person-one.png" />
      </div>

      <div className={s.personsBlock__person}>
        <img src="/images/green-edge/person-two.png" />
      </div>

      <div className={s.personsBlock__person}>
        <img src="/images/green-edge/person-three.png" />
      </div>

      <div className={s.personsBlock__person}>
        <img src="/images/green-edge/person-four.png" />
      </div>
    </div>

    <div className={s.textBlock}>
      <span>On average people</span>
      <br />
      <span>
        save <span className={s.textBlock__textAccetned}>$900/year</span>
      </span>
    </div>
  </div>
);
