import {
  isDevelopmentMode,
  isProductionLocation,
  isStagingLocation,
} from '@/utils/app';
import { type InsuranceHost } from '@insurance/utils';

export const getApiBasePath = (host?: InsuranceHost) => {
  if (isDevelopmentMode()) {
    return typeof window !== 'undefined'
      ? window.location.origin
      : 'http://127.0.0.1:3010';
  }

  if (isStagingLocation()) {
    return 'https://insurance.devpr.net';
  }

  if (isProductionLocation() && host !== undefined) {
    return `https://${host}`;
  }

  return '';
};
