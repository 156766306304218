import React from 'react';
import { Button } from '@/components/green-edge-landing/common/button';
import s from './style.module.scss';

import carImage from './images/personalized-car.png';

type TPersonalizedQuoteProps = {
  onClick(): void;
};

export const PersonalizedQuote: React.FC<TPersonalizedQuoteProps> = ({
  onClick,
}) => (
  <div className={s.root}>
    <div className={s.textBlock}>
      <div className={s.title}>Get a Personalized Insurance Quote</div>

      <Button variant="filled" color="primary" size="md" onClick={onClick}>
        View My Quotes
      </Button>
    </div>

    <img className={s.carImage} src={carImage.src} alt="Car" />
  </div>
);
