import React from 'react';

export const NoIcon: React.FC = () => (
  <svg
    width="256"
    height="256"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M128 234.667C128 234.667 213.333 192 213.333 128V53.3333L128 21.3333L42.6667 53.3333V128C42.6667 192 128 234.667 128 234.667Z"
      stroke="currentColor"
      strokeWidth="16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M154.667 96L101.333 149.333"
      stroke="currentColor"
      strokeWidth="16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M101.333 96L154.667 149.333"
      stroke="currentColor"
      strokeWidth="16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
