import { forwardRef } from 'react';
import cn from 'classnames';
import { type TComponentProps } from '@/components/myinsurance-landing/common/types';
import s from './style.module.scss';

type TSectionProps = TComponentProps & { id?: string };

export const Section = forwardRef<HTMLDivElement, TSectionProps>(
  ({ className, children, id }, ref) => (
    <div className={cn(className, s.root)} ref={ref} id={id}>
      {children}
    </div>
  ),
);

Section.displayName = 'Section';
