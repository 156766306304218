import React, { type FC, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { getUrlParam } from '@insurance/utils';
import { AnalyticsGoals } from '@insurance/form-api';
import { formApi } from '@/services/form-api';
import { Button } from '@/components/green-edge-landing/common/button';
import { Field } from '../field';
import { useFormState } from './use-form-state';
import s from './style.module.scss';

const isZipCodeValid = (value: string) => /^\d{5}$/i.test(value);

const prepareDataForForm = (zipCode: string) => ({
  ...(zipCode ? { zipCode } : {}),
});

type TShortFormProps = {
  className?: string;
  buttonText: React.ReactNode;
  onSubmit(values: { zipCode?: string }): void;
};

export const ShortForm: FC<TShortFormProps> = ({
  className,
  buttonText,
  onSubmit,
}) => {
  const [formState, updateFormState] = useFormState();

  const onZipCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      updateFormState({
        type: 'zipCode',
        payload: {
          value,
          errorMessage: null,
        },
      });
    },
    [updateFormState],
  );

  const onFormSubmit = () => {
    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingGoToForm,
      payload: {
        referrer: 'getMyQuotes',
      },
    });

    onSubmit(prepareDataForForm(formState.zipCode.value));
  };

  useEffect(() => {
    if (
      formState.zipCode.value === '' ||
      !isZipCodeValid(formState.zipCode.value)
    ) {
      return;
    }

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingFieldIsFilled,
      payload: {
        field: 'zip',
        value: formState.zipCode.value,
      },
    });
  }, [formState.zipCode.value, updateFormState]);

  useEffect(() => {
    const zipCode = getUrlParam('zipCode');

    if (!zipCode) {
      return;
    }

    updateFormState({
      type: 'zipCode',
      payload: {
        value: zipCode,
        errorMessage: null,
      },
    });
  }, [updateFormState]);

  return (
    <div className={cn(className, s.form)}>
      <Field
        className={cn(s.field, s.field_zip)}
        type="tel"
        value={formState.zipCode.value}
        onChange={onZipCodeChange}
        maxLength={5}
        uiSize="lg"
        placeholder="90210"
        label="Enter your ZIP code"
      />

      <Button size="lg" variant="filled" color="primary" onClick={onFormSubmit}>
        {buttonText}
      </Button>
    </div>
  );
};
