import { InsuranceHost } from '@insurance/utils';
import desktopImage from '../images/desktop.png';
import desktopImageMyInsuranceMarket from '../images/desktop-mim.png';

export const getDesktopImage = (host: InsuranceHost) => {
  switch (host) {
    case InsuranceHost.MyInsuranceMarket:
      return desktopImageMyInsuranceMarket;
    default:
      return desktopImage;
  }
};
