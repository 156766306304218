import { useCallback, useEffect, useRef, useState } from 'react';

export const useUpdatingByTimer = (timerValue: number) => {
  const [updating, setUpdating] = useState(false);

  const intervalRef = useRef(-1);

  const update = useCallback(() => {
    setUpdating(true);

    intervalRef.current = window.setTimeout(
      () => setUpdating(false),
      timerValue,
    );
  }, [timerValue]);

  useEffect(
    () => () => {
      if (intervalRef.current > -1) {
        window.clearTimeout(intervalRef.current);
      }
    },
    [],
  );

  return {
    updating,
    update,
  };
};
