import s from './style.module.scss';

export const OfferWallSkeleton: React.FC = () => (
  <div className={s.root}>
    {/* <div className={s.logo} /> */}
    {/* <div className={s.rating} /> */}
    {/* <div className={s.discount} /> */}
    {/* <div className={s.maxDiscount} /> */}
    <div className={s.button} />
  </div>
);
