import { logger } from '@/utils/logger';
import { US_STATES_MAPPING } from '@/constants';

type TGetUsStateByIpData = {
  ip?: string;
  countryCode?: string;
  countryRegionCode?: string;
};

export const getUsStateByIp = async (): Promise<string | undefined> => {
  try {
    const response = await fetch('/api/databank/ip-info/');

    if (!response.ok) {
      throw new Error(`US state by ip failed with status ${response.status}`);
    }

    const data: TGetUsStateByIpData = await response.json();

    if (
      data.countryCode !== 'US' ||
      !data.countryRegionCode ||
      !US_STATES_MAPPING[data.countryRegionCode]
    ) {
      // Unable extract US state from non-US ip, skip
      return undefined;
    }

    return US_STATES_MAPPING[data.countryRegionCode];
  } catch (error) {
    logger.error(error);

    return undefined;
  }
};
