import React from 'react';
import Image from 'next/image';
import { Section } from '@/components/green-edge-landing/common/section';
import { Container } from '@/components/green-edge-landing/common/container';
import { type InsuranceHost } from '@insurance/utils';
import { PeopleSavingCard } from './people-saving-card';
import { getPhoneImage, getDesktopImage, getDesktopMobileImage } from './utils';
import s from './style.module.scss';

type TSectionWhyUsProps = {
  host: InsuranceHost;
};
export const SectionWhyUs: React.FC<TSectionWhyUsProps> = ({ host }) => {
  const phoneImage = getPhoneImage(host);
  const desktopImage = getDesktopImage(host);
  const desktopMobileImage = getDesktopMobileImage(host);

  return (
    <Section className={s.root}>
      <Container className={s.container}>
        <div className={s.screensBlock}>
          <Image
            className={s.screensBlock__desktopScreenshot}
            alt="Dashboard desktop screenshot"
            src={desktopImage}
            width={desktopImage.width / 2}
            height={desktopImage.height / 2}
          />

          <Image
            className={s.screensBlock__desktopScreenshotMobile}
            alt="Dashboard desktop screenshot"
            src={desktopMobileImage}
            width={desktopMobileImage.width / 2}
            height={desktopMobileImage.height / 2}
          />

          <Image
            className={s.screensBlock__mobileScreenshot}
            alt="Dashboard mobile screenshot"
            src={phoneImage}
            width={phoneImage.width / 2}
            height={phoneImage.height / 2}
          />

          <PeopleSavingCard />
        </div>

        <div className={s.advantagesBlock}>
          <div className={s.advantagesBlock__title}>
            Here’s why our customers love us:
          </div>

          <div className={s.advantagesBlock__advantages}>
            <div className={s.advantagesBlock__advantages__advantage}>
              Get personalized
              <br />
              quotes in 5 minutes
            </div>

            <div className={s.advantagesBlock__advantages__advantage}>
              Compare prices among
              <br />
              America&apos;s top insurers
            </div>

            <div className={s.advantagesBlock__advantages__advantage}>
              Huge savings.
              <br />
              Great coverage
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};
