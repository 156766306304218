import React, { type FC, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { gtag } from '@insurance/utils';
import { AnalyticsGoals } from '@insurance/form-api';
import { formApi } from '@/services/form-api';
import { useViewportVisibility } from '@/hooks/viewport-visibility';
import { useScrollProgress } from '@/hooks/scroll-progress';
import { Button } from '@/components/green-edge-landing/common/button';
import { MyInsuranceLogo } from '@/components/green-edge-landing/common/logo';
import { UsStateBlock } from '@/components/green-edge-landing/sections/hero/us-state-block';
import { RatingCard } from '@/components/green-edge-landing/sections/hero/rating-card';
import { Phone } from 'phosphor-react';
import { useUsStateByIp } from '@/hooks/use-us-state-by-ip';
import { useMedia } from 'react-use';
import { TalkToUsBlock } from '@/components/green-edge-landing/sections/hero/talk-to-us';
import { FilterControl } from '@/components/green-edge-landing/sections/hero/filter-control';
import { PersonalizedQuote } from '@/components/green-edge-landing/sections/hero/personalized-quote';
import { SessionOffers } from '@/components/green-edge-landing/common/session-offers';
import { useUpdatingByTimer } from '@/hooks/use-updating-by-timer';
import { useAppConfig } from '@insurance/app-config';
import { Container } from '../../common/container';
import { Section } from '../../common/section';
import { ShortForm } from '../../common/form';
import s from './style.module.scss';
import { ReactComponent as BrandOneImage } from './images/brand-one.svg';
import { ReactComponent as BrandTwoImage } from './images/brand-two.svg';
import { ReactComponent as BrandThreeImage } from './images/brand-three.svg';

type TOfferFilterId = 'goodDriver' | 'married' | 'homeowner' | 'multipleCar';

type TOfferFilter = {
  id: TOfferFilterId;
  name: string;
  discount: number;
};

const DEFAULT_OFFER_FILTERS: TOfferFilter[] = [
  {
    id: 'goodDriver',
    name: 'Good Driver',
    discount: 14,
  },
  {
    id: 'married',
    name: 'Married',
    discount: 5,
  },
  {
    id: 'homeowner',
    name: 'Homeowner',
    discount: 2,
  },
  {
    id: 'multipleCar',
    name: 'Multiple Car',
    discount: 5,
  },
];

const VISIBLE_OFFERS_COUNT = 3;

const DEFAULT_ACTIVE_FILTERS: TOfferFilterId[] = ['goodDriver'];

const DISCOUNT_UPDATING_TIMER_VALUE = 600;

type TSectionHeroProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  buttonText: React.ReactNode;
  onGetQuotes(values?: { zipCode?: string }): void;
  usStateParam: string;
};

export const SectionHero: FC<TSectionHeroProps> = ({
  title,
  subtitle,
  buttonText,
  onGetQuotes,
  usStateParam,
}) => {
  const { isSavvyPartnerMode, contactPhone, host } = useAppConfig();

  const imageRef = useRef<HTMLImageElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const fabRef = useRef<HTMLDivElement>(null);
  const stickyHeaderRef = useRef<HTMLDivElement>(null);
  const headerSubsectionRef = useRef<HTMLDivElement>(null);

  const usState = useUsStateByIp(usStateParam);

  const isMobile = useMedia('(max-width: 767px)', false);

  useScrollProgress({
    trackedRef: headerRef,
    range: [
      0,
      () =>
        headerRef.current
          ? headerRef.current.getBoundingClientRect().bottom
          : 0,
    ],
    origin: 'bottom',
    easing: 'linear',
  });

  useScrollProgress({
    trackedRef: imageRef,
    range: [
      0,
      () =>
        imageRef.current ? imageRef.current.getBoundingClientRect().bottom : 0,
    ],
    origin: 'bottom',
    easing: 'easeIn',
  });

  useViewportVisibility({
    ref: headerRef,
    onEnter: () => {
      if (fabRef.current) {
        fabRef.current.classList.remove(s.isShown);
      }
    },
    onExit: () => {
      if (fabRef.current) {
        fabRef.current.classList.add(s.isShown);
      }
    },
  });

  useViewportVisibility({
    ref: headerSubsectionRef,
    onEnter: () => {
      if (stickyHeaderRef.current) {
        stickyHeaderRef.current.classList.remove(s.isShown);
      }
    },
    onExit: () => {
      if (stickyHeaderRef.current) {
        stickyHeaderRef.current.classList.add(s.isShown);
      }
    },
  });

  const handleClickBottomGetQuotes = () => {
    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingClickButton,
      payload: {
        buttonType: 'bottomGetQuotes',
      },
    });

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingGoToForm,
      payload: {
        referrer: 'bottomGetQuotes',
      },
    });

    onGetQuotes();
  };

  const handlePersonalizedQuoteClick = () => {
    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingClickButton,
      payload: {
        buttonType: 'personalizedQuote',
      },
    });

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingGoToForm,
      payload: {
        referrer: 'personalizedQuote',
      },
    });

    onGetQuotes();
  };

  const handleClickCall = () => {
    gtag({ event: 'click_to_call' });

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingClickCall,
    });
  };

  const handleTalkToUsClick = () => {
    gtag({ event: 'click_to_call' });

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingTalkToUsClick,
    });
  };

  const headerSubsection = useMemo(
    () => (
      <div className={s.headerSubsection}>
        <Link className={s.headerSubsection__logo} href="/">
          <MyInsuranceLogo
            landingType="myinsurancemarket"
            host={host}
            color="black"
            logoTextColor="black"
          />
        </Link>

        <div className={s.headerSubsection__actionsBlock}>
          <Button
            className={s.phoneCallButton}
            variant="filled"
            color="primary"
            size="md"
            href={contactPhone.href}
            onClick={handleClickCall}
            IconStart={Phone}
          >
            {isMobile ? 'Call' : contactPhone.label}
          </Button>
        </div>
      </div>
    ),
    [host, isMobile],
  );

  const [activeFilterIds, setActiveFilterIds] = useState(
    DEFAULT_ACTIVE_FILTERS,
  );

  const { updating: discountUpdating, update: updateDiscount } =
    useUpdatingByTimer(DISCOUNT_UPDATING_TIMER_VALUE);

  const toggleFilterActive = (filterId: TOfferFilterId) => {
    gtag({ event: 'click_to_call' });

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingFiltersChange,
      payload: {
        name: filterId,
        state: activeFilterIds.includes(filterId) ? 'off' : 'on',
      },
    });

    updateDiscount();

    setActiveFilterIds((prevActiveFilterIds) =>
      prevActiveFilterIds.includes(filterId)
        ? prevActiveFilterIds.filter((offer) => offer !== filterId)
        : [...prevActiveFilterIds, filterId],
    );
  };

  const currentEstDiscount = useMemo(
    () =>
      activeFilterIds.reduce((acc, offerId) => {
        const offer = DEFAULT_OFFER_FILTERS.find(
          (defaultOffer) => defaultOffer.id === offerId,
        )!;

        // eslint-disable-next-line no-param-reassign
        acc += offer.discount;

        return acc;
      }, 0),
    [activeFilterIds],
  );

  return (
    <Section className={s.root} id="sectionHero">
      <div className={cn(s.segment, s.segment_header)} ref={headerRef}>
        <Container className={s.container}>
          <div
            className={s.headerSubsectionContainer}
            ref={headerSubsectionRef}
          >
            {headerSubsection}
          </div>

          <div className={s.bannerSubsection}>
            <div className={s.titleBlock}>
              {usState && <UsStateBlock usState={usState} />}

              <div className={s.titleBlock__title}>{title}</div>

              <div className={s.titleBlock__subtitle}>{subtitle}</div>

              <ShortForm
                className={s.titleBlock__shortForm}
                buttonText={buttonText}
                onSubmit={onGetQuotes}
              />

              <TalkToUsBlock onPhoneClick={handleTalkToUsClick} />
            </div>

            <div className={s.bannerBlock}>
              <div className={s.bannerBlock__bannerGirl}>
                <div className={s.bannerBlock__bannerGirlBackground} />

                <img
                  className={s.bannerBlock__bannerGirlImage}
                  alt="Girl"
                  src="/images/green-edge/banner-girl.png"
                />
              </div>

              <div className={s.bannerBlock__cardsContainer}>
                <RatingCard
                  brandImage={<BrandOneImage />}
                  price={32}
                  rating={4.6}
                />

                <RatingCard
                  brandImage={<BrandTwoImage />}
                  price={29}
                  rating={4.8}
                />

                <RatingCard
                  brandImage={<BrandThreeImage />}
                  price={47}
                  rating={4.5}
                />
              </div>
            </div>
          </div>

          {!isSavvyPartnerMode && (
            <div className={s.exploreBestOffersSubsection}>
              <div className={s.exploreBestOffersSubsection__title}>
                Explore best offers
              </div>

              <div className={s.exploreBestOffersSubsection__content}>
                <div className={s.filterControlsBlock}>
                  <div className={s.filterControlsBlock__title}>
                    Select discounts that apply to you:
                  </div>

                  <div className={s.filterControlsBlock__controls}>
                    {DEFAULT_OFFER_FILTERS.map((offerFilter) => (
                      <FilterControl
                        key={offerFilter.id}
                        name={offerFilter.name}
                        discount={offerFilter.discount}
                        checked={activeFilterIds.includes(offerFilter.id)}
                        onCheck={() => toggleFilterActive(offerFilter.id)}
                      />
                    ))}
                  </div>
                </div>

                <div className={s.offersBlock}>
                  <SessionOffers
                    visibleCount={VISIBLE_OFFERS_COUNT}
                    currentEstDiscount={currentEstDiscount}
                    discountUpdating={discountUpdating}
                  />

                  <PersonalizedQuote onClick={handlePersonalizedQuoteClick} />
                </div>
              </div>
            </div>
          )}
        </Container>
      </div>

      <div className={cn(s.segment, s.segment_fab)} ref={fabRef}>
        <Container className={s.container}>
          <div className={s.title}>
            <span className={s.title__accented}>Save up to 20%</span>
            <br />
            <span className={s.title__regular}>on your policy!</span>
          </div>

          <Button
            variant="filled"
            color="primary"
            size="md"
            onClick={handleClickBottomGetQuotes}
          >
            Get quotes
          </Button>
        </Container>
      </div>

      <div
        className={cn(s.segment, s.segment_stickyHeader)}
        ref={stickyHeaderRef}
      >
        <Container className={s.container}>{headerSubsection}</Container>
      </div>
    </Section>
  );
};
