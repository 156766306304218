import type { TOffer } from '@insurance/entities';

export const getOfferLink = (
  offer: TOffer,

  data?: {
    analyticsSearchParams: Record<string, string>;
    analyticsPayload: { source?: string; popup?: boolean };
  },
) => {
  try {
    const offerUrl = new URL(offer.link);

    const { analyticsPayload: payload, analyticsSearchParams } = data || {};

    offerUrl.searchParams.append('key', offer.key);

    offerUrl.searchParams.append('offerNumber', String(offer.offerNumber));

    const analyticsPayload = {
      key: offer.key,
      offerNumber: String(offer.offerNumber),
      ...payload,
    };

    offerUrl.searchParams.append(
      'analyticsPayload',
      encodeURIComponent(JSON.stringify(analyticsPayload)),
    );

    offerUrl.searchParams.append(
      'analyticsSearchParams',
      encodeURIComponent(JSON.stringify(analyticsSearchParams)),
    );

    offerUrl.searchParams.append('triggeredAt', String(Date.now()));

    return offerUrl.toString();
  } catch {
    return offer.link;
  }
};
