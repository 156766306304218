import { useEffect, useState } from 'react';
import {
  getAidFromQuery,
  getSourceFromQuery,
  getUrlParam,
} from '@insurance/utils';
import { type TOffer } from '@insurance/entities';
import { useSessionId } from '@/components/session-id-provider';
import { formApi } from '@/services/form-api';
import { getOfferLink } from '@insurance/utils/src/offer';
import { getOfferWallFromInternalApi } from '@/api/get-offer-wall-from-internal-api';
import { useAppConfig } from '@insurance/app-config';
import { useIsMounted } from './use-is-mounted';

interface TSessionOffersState {
  offers: TOffer[];
  loading: boolean;
  loaded: boolean;
  failed: boolean;
}

export const useSessionOffers = () => {
  const { sessionId, initialized: sessionIdInitialized } = useSessionId();

  const isMounted = useIsMounted();
  const { host } = useAppConfig();

  const [state, setState] = useState<TSessionOffersState>({
    offers: [],
    loading: true,
    loaded: false,
    failed: false,
  });

  useEffect(() => {
    if (!sessionIdInitialized) {
      return;
    }

    const loadOffers = async () => {
      try {
        const offers = await getOfferWallFromInternalApi(
          'landing',
          {
            sessionId,
            zip: getUrlParam('zipCode'),
            aid: getAidFromQuery(),
            source: getSourceFromQuery(),
          },
          host,
        );

        const analyticsSearchParams = await formApi.getAnalyticsParams();

        if (isMounted()) {
          setState((prevState) => ({
            ...prevState,
            offers: offers.map((offer) => ({
              ...offer,
              link: getOfferLink(offer, {
                analyticsPayload: { source: 'landing-offer-wall' },
                analyticsSearchParams: analyticsSearchParams ?? {},
              }),
            })),
            loading: false,
            loaded: true,
          }));
        }
      } catch (error) {
        if (isMounted()) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            failed: true,
          }));
        }
      }
    };

    loadOffers();
  }, [sessionId, sessionIdInitialized, isMounted]);

  return state;
};
