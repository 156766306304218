import { logger } from '@/utils/logger';
import {
  DEFAULT_INSURANCE_HOST,
  getUrlWithParams,
  type InsuranceHost,
} from '@insurance/utils';
import {
  type TOffer,
  type TGetOfferWallParams,
  type TOfferWallType,
  type TGetOfferWallResponse,
} from '@insurance/entities';
import { getApiBasePath } from '@/utils/api';

export const getOfferWallFromInternalApi = async (
  type: TOfferWallType,
  params: TGetOfferWallParams = {},
  host: InsuranceHost = DEFAULT_INSURANCE_HOST,
): Promise<TOffer[]> => {
  const url = `${getApiBasePath(host)}/internal-api/offer-wall/`;

  try {
    const response = await fetch(getUrlWithParams(url, { type, ...params }));

    if (!response.ok) {
      throw new Error(
        `Landing offer-wall failed with status ${response.status}`,
      );
    }

    const offerWallResponse: TGetOfferWallResponse = await response.json();

    return offerWallResponse.offers;
  } catch (error) {
    logger.error(error);

    throw error;
  }
};
