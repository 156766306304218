import React from 'react';
import s from './style.module.scss';
import { ReactComponent as UsFlagIcon } from './images/us-flag.svg';

type TUsStateBlockProps = {
  usState?: string;
};

export const UsStateBlock: React.FC<TUsStateBlockProps> = ({ usState }) => (
  <div className={s.usStateSection}>
    <UsFlagIcon className={s.usFlag} />
    <span>Your State: </span>
    <span className={s.usStateName}>{usState}</span>
  </div>
);
