import React from 'react';
import { Rating } from '@/components/green-edge-landing/common/rating';
import s from './style.module.scss';

type TRatingCardProps = {
  brandImage: React.ReactNode;
  price: number;
  rating: number;
};

export const RatingCard: React.FC<TRatingCardProps> = ({
  brandImage,
  price,
  rating,
}) => (
  <div className={s.cardContainer}>
    <div className={s.brandImage}>{brandImage}</div>

    <div className={s.brandInfo}>
      <Rating className={s.rating} value={rating} />

      <div className={s.price}>${price}/mo</div>
    </div>
  </div>
);
