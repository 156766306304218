import React from 'react';
import { useAppConfig } from '@insurance/app-config';
import s from './style.module.scss';

type TTalkToUsBlock = {
  onPhoneClick(): void;
};

export const TalkToUsBlock: React.FC<TTalkToUsBlock> = ({ onPhoneClick }) => {
  const { contactPhone } = useAppConfig();

  return (
    <div className={s.talkToUsBlock}>
      <span className={s.label}>Or talk to us at</span>

      <a href={contactPhone.href} className={s.phone} onClick={onPhoneClick}>
        {contactPhone.label}
      </a>
    </div>
  );
};
