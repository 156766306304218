import { useReducer } from 'react';

export type TFormState = {
  zipCode: {
    value: string;
    errorMessage: string | null;
  };
};

export type TFormAction = {
  type: keyof TFormState;
  payload: object;
};

const initialState: TFormState = {
  zipCode: {
    value: '',
    errorMessage: null,
  },
};

const reducer = (state: TFormState, action: TFormAction) => {
  switch (action.type) {
    case 'zipCode':
      return { ...state, zipCode: { ...state.zipCode, ...action.payload } };

    default:
      return state;
  }
};

export const useFormState = () => useReducer(reducer, initialState);
