import { type FC, type HTMLProps, type ReactNode } from 'react';
import cn from 'classnames';
import { type Icon } from 'phosphor-react';
import s from './style.module.scss';

type TProps = Omit<
  HTMLProps<HTMLButtonElement & HTMLAnchorElement>,
  'size' | 'label'
> & {
  className?: string;
  children?: ReactNode;
  size?: 'md' | 'lg' | 'xl';
  variant?: 'filled' | 'text';
  color?: 'primary' | 'secondary' | 'neutral';
  IconStart?: Icon;
  IconEnd?: Icon;
  href?: string;
};

export const Button: FC<TProps> = ({
  className,
  size = 'md',
  variant = 'text',
  color = 'neutral',
  children,
  IconStart,
  IconEnd,
  href,
  ...props
}) => {
  const RootElement = href ? 'a' : 'button';

  const rootCssClass = cn(
    className,
    s.button,
    s[`size_${size}`],
    s[`variant_${variant}`],
    s[`color_${color}`],
  );

  return (
    <RootElement className={rootCssClass} {...props} type="button" href={href}>
      <div className={s.inner}>
        {IconStart && <IconStart className={cn(s.icon, s.icon_start)} />}
        {children}
        {IconEnd && <IconEnd className={cn(s.icon, s.icon_end)} />}
      </div>
    </RootElement>
  );
};
