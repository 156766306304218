import { InsuranceHost } from '@insurance/utils';
import phoneImage from '../images/phone.png';
import phoneImageMyInsuranceMarket from '../images/phone-mim.png';

export const getPhoneImage = (host: InsuranceHost) => {
  switch (host) {
    case InsuranceHost.MyInsuranceMarket:
      return phoneImageMyInsuranceMarket;
    default:
      return phoneImage;
  }
};
