import React from 'react';
import { InsuranceHost } from '@insurance/utils';
import { LumixalLanding } from '@/components/lumixal-landing';
import { GreenEdgeLanding } from '@/components/green-edge-landing';
import { useAppConfig } from '@insurance/app-config';

export const Landing: React.FC = () => {
  const { host, usStateParam } = useAppConfig();

  switch (host) {
    case InsuranceHost.MyAutoArmor:
    case InsuranceHost.AllCheapQuotes:
    case InsuranceHost.SolaceAuto:
    case InsuranceHost.PragmaAuto:
      return <LumixalLanding />;

    default:
      return <GreenEdgeLanding usStateParam={usStateParam} host={host} />;
  }
};
