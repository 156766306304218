import cn from 'classnames';
import { useContactEmail } from '@/hooks/use-contact-email';
import { Section } from '@/components/green-edge-landing/common/section';
import { Container } from '@/components/green-edge-landing/common/container';
import s from './style.module.scss';

type TSectionFooterProps = {
  onContactClick?(): void;
};

export const SectionFooter: React.FC<TSectionFooterProps> = ({
  onContactClick,
}) => {
  const contactEmail = useContactEmail();

  return (
    <Section className={s.root}>
      <Container className={s.container}>
        <div className={s.linkGroup}>
          <a className={s.link} href="/page/privacy-policy" target="_blank">
            Privacy Policy
          </a>

          <a className={s.link} href="/page/terms-of-use" target="_blank">
            Terms Of Use
          </a>

          <a className={s.link} href="/page/cookies-policy" target="_blank">
            Cookies Policy
          </a>

          <button
            className={cn(s.contactUsButton, s.link)}
            onClick={onContactClick}
            type="button"
          >
            Contact Us
          </button>

          <a
            className={s.link}
            href="/landing-auth/unsubscribe"
            target="_blank"
          >
            Unsubscribe
          </a>

          <div className={s.contactEmail}>
            <span className={s.contactEmail__label}>Customer Service: </span>
            <span className={s.contactEmail__email}>{contactEmail}</span>
          </div>
        </div>

        <div className={s.disclaimer}>
          We&nbsp;make every effort to&nbsp;ensure the accuracy and timeliness
          of&nbsp;the information we&nbsp;provide. However, the information you
          see when you visit a&nbsp;financial institution, service provider,
          or&nbsp;product site may differ from ours. We&nbsp;present all
          financial products, shopping products, and services without warranty.
          It&rsquo;s important to&nbsp;review the terms and conditions
          of&nbsp;any financial institution before making a&nbsp;decision.
          Please note that pre-qualified offers are not legally binding.
        </div>
      </Container>
    </Section>
  );
};
