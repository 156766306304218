import { useEffect, useState } from 'react';

import { getUsStateByIp } from '@/api/get-us-state-by-ip';

export const useUsStateByIp = (initialUsState: string) => {
  const [usState, setUsState] = useState(initialUsState);

  useEffect(() => {
    const initUsStateByIp = async () => {
      const usStateByIp = await getUsStateByIp();

      if (usStateByIp !== undefined) {
        setUsState(usStateByIp);
      }
    };

    if (!initialUsState) {
      initUsStateByIp();
    }
  }, [initialUsState]);

  return usState;
};
